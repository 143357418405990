body .Fullscreen{
    position: fixed;
    right: 5px;
    top: 5px;
    background-color: var(--color-gray);
    border: none;
    z-index: 10;
    width: 35px;
    height: 35px;
    outline: none;
}
.Fullscreen.isFullscreen{
    /* display: none; */
}
.Fullscreen::after , .Fullscreen::before{
    content: '';
    display: flex;
    width: 12px;
    height: 12px;
    border: solid 2px white;
    position: absolute;
    transition: 300ms;
}
.Fullscreen::after{
    bottom: 5px;
    left: 5px;
    border-top: none;
    border-right: none;
}
.Fullscreen::before{
    top: 5px;
    right: 5px;
    border-bottom: none;
    border-left: none;
}
.Fullscreen:hover::before{
    top: 3px;
    right: 3px;
}
.Fullscreen:hover::after{
    bottom: 3px;
    left: 3px;
}
