/* @import '~antd/dist/antd.dark.css'; */
/* @import '~antd/dist/antd.compact.css'; */

html {
	font-size: 14px;
}
body {
	font-size: inherit;
}
@media (min-width: 1200px) {
	html {
		font-size: 16px;
	}
}
@media (min-width: 1600px) {
	html {
		font-size: 17px;
	}
}
.ant-divider-horizontal.ant-divider-with-text {
	font-size: 1.2rem;
}
#root {
	background-color: hsl(216 20% 90% / 1);
	position: relative;
	--color-assent: hsl(205deg 60% 60%);
	--color-gray: hsl(0 0% 25% / 1);
	--color-green: hsl(120deg 60% 60%);
}

.color-green {
	color: var(--color-green);
}
.App {
	display: flex;
	/* flex-wrap: wrap; */
	/* flex-direction: column; */
	min-height: 100%;
	width: 100%;
	min-height: 100vh;
	/* max-width: 1400px; */
	align-items: flex-start;
	position: relative;
}
.App::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background-image: url("https://cssauthor.com/wp-content/uploads/2015/01/Lazeez-watercolor-textures-4K-UDH-backgrounds.jpg"); */
	filter: grayscale(20%);
	/* filter: blur(5px); */
	background-size: cover;
}
.App > * {
	position: relative;
	z-index: 2;
}
.Page {
	display: flex;
	flex-wrap: wrap;
	/* flex-direction: column; */
	min-height: 100%;
	width: 100%;
	min-height: 100vh;
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
	align-items: flex-start;
	padding-left: 15px;
	padding-right: 15px;
}
header {
	/* flex-basis: 100%; */
	width: 100%;
	height: 100px;
}
main {
	display: flex;
	color: white;
	flex-grow: 1;
	/* grid-row-start: 2; */
	/* flex-basis: 60%; */
	width: 40%;
	margin-right: 15px;
	padding: 15px;
}
aside {
	width: 50%;
}

.ant-table-row.ant-table-row-level-0.isActive > * {
	background-color: hsl(0 0% 80% / 1) !important;
}

.App-link {
	color: #61dafb;
}

.ant-table.ant-table-small
	.ant-table-tbody
	.ant-table-wrapper:only-child
	.ant-table {
	/* margin-left: 40px; */
	/* margin-left: 0px; */
	/* width: 100%; */
}
.ant-table-content {
	/* padding-right: 31px; */
}

@media (max-width: 700px) {
	main {
		width: 100%;
	}
	.sct-orders {
		width: 100%;
	}
	.ant-table-content {
		overflow-x: auto;
	}
}
