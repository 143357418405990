nav {
	/* position: sticky; */
	/* left: 0; */
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: hsl(0 0% 25% / 1);
	margin-right: 15px;
	align-self: stretch;
	color: white;
}
nav ul {
	margin: 0;
	padding: 15px;
}
nav li {
	margin: 5px 0;
	list-style: none;
}
nav a {
	padding: 5px;
	color: inherit;
	display: flex;
	flex-direction: column;
	align-items: center;
}

nav .img-wrap {
	width: 30px;
	height: 30px;
}
nav .img-wrap svg,
nav .img-wrap span[role="img"] {
	max-width: 100%;
	width: 100%;
	height: 100%;
	fill: white;
	transition: 100ms;
}
nav .img-wrap.stroke svg {
	stroke: white;
}
nav a:hover .img-wrap svg,
nav a.active .img-wrap svg {
	fill: var(--color-assent);
}
nav a:hover,
nav a.active {
	color: var(--color-assent);
	opacity: 1;
}
nav a:hover .img-wrap.stroke svg,
nav a.active .img-wrap.stroke svg {
	stroke: var(--color-assent);
}

nav .img-wrap.IconFood {
	max-width: 25px;
}
nav .img-wrap.IconKnife {
	max-width: 35px;
}

@media (max-width: 700px) {
	.App {
		padding-top: 45px;
	}
	body nav {
		min-height: auto;
		width: 100%;
		width: 100%;
		position: fixed !important;
		z-index: 3 !important;
		display: flex;
		flex-direction: row;
		align-items: center;
		top: 0;
	}
	nav ul {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 0;
	}
	nav .Avatar {
		width: auto;
		margin: 0;
	}
	nav li {
		margin: 0;
	}
	nav li a {
		margin: 0 5px;
		padding: 0;
		font-size: 0;
	}
}
.version {
	margin-top: auto;
	width: 100%;
	text-align: center;
	font-size: 14px;
	font-family: serif;
}
@media (max-width: 768px) {
	.version {
		font-size: 12px;
		margin: 0;
		position: fixed;
		bottom: 0;
		right: 0;
		width: 100px;
		border-radius: 10px 0 0 0;
		background-color: inherit;
	}
}
