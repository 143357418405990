.table-controls{
    display: grid;
    gap: 15px;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
}
.table-controls .ant-form-item{
    margin: 0;
    /* margin-right: 15px; */
}
body .table-controls > *{
    /* margin-right: 15px; */
    /* margin-bottom: 15px; */
}