.OrderDatail {
	/* position: sticky; */
	/* top: 0; */
}
.OrderDatail .close{
    display: none;
}
.OrderDatail ul {
	list-style: none;
	padding-left: 0;
}
.OrderDatail ul.block-ul > li {
	display: flex;
	margin-bottom: 5px;
}
.OrderDatail ul.block-ul > li > span {
	width: 80%;
}
.OrderDatail ul.block-ul > li > span:first-child {
	width: 20%;
	text-align: right;
	padding-right: 15px;
}
.block {
	background-color: #fff;
	border-radius: 2px;
	padding: 15px;
}

.OrderDatail li.status span{
	display: flex;
	align-items: center;
}
.OrderDatail.onChangeAnimation {
	animation-timing-function: ease-in-out;
	animation-name: headShake;
	animation-duration: 1000ms;
}
.OrderDatail .comments{}
.OrderDatail .comments li{}
.OrderDatail .comments .added-by{
    background-color: gray;
    align-self: flex-start;
    border-radius: 5px;
    text-align: center;
    padding: 0 5px;
    color: white;
}
@keyframes headShake {
	0% {
		transform: translateX(0);
	}

	6.5% {
		transform: translateX(-6px) rotateY(-9deg);
	}

	18.5% {
		transform: translateX(5px) rotateY(7deg);
	}

	31.5% {
		transform: translateX(-3px) rotateY(-5deg);
	}

	43.5% {
		transform: translateX(2px) rotateY(3deg);
	}

	50% {
		transform: translateX(0);
	}
}

.OrderDatail.onChangeAnimation li.status {
	animation-timing-function: ease-in-out;
	animation-name: bgc;
	animation-duration: 10000ms;
	animation-fill-mode: forwards;
}
@keyframes bgc {
	0% {
		background-color: hsl(62 96% 57% / 1);
	}
	50% {
		background-color: hsl(62 96% 57% / 0.5);
	}
	50% {
		background-color: hsl(62 96% 57% / 0);
	}
}

@media(max-width: 1200px){
    .OrderDatail{
        position: fixed;
        left: 15px;
        right: 0px;
        top: 45px;
        box-shadow: 0px 0px 30px 0px grey;
        background-color: white;
        max-height: calc(100vh - 55px);
        overflow-y: auto;
    }
    .OrderDatail .close{
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
    }
    .OrderDatail .close button{
        background-color: var(--color-gray);
    }
    .OrderDatail .block{
        /* overflow-y: auto; */
        /* padding-bottom: 140px; */
        /* max-height: calc(100vw - 40px); */
    }
}