.ProductionList {
	width: 100%;
	box-shadow: inset 0px 0px 5px -1px hsl(0deg 0% 70%);
	margin-bottom: 0;
	padding: 1em;
}
.ProductionList li {
	margin-bottom: 0.5em;
	list-style: none;
	font-size: 0.9em;
}
.ProductionList li button {
	margin-right: 1em;
}
.ProductionList li .cell {
	display: inline-flex;
	margin-right: 1rem;
	min-width: 100px;
}
.ProductionList li {
	opacity: 1;
	max-height: 40px;
	overflow: hidden;
}
.ProductionList li.hide {
	transition: 400ms;
	opacity: 0;
	max-height: 0px;
}
