.page-stats {
	margin: 0;
	padding: 0;
}

.ProductsStats {
	/* padding: 15px; */
	--width: 120px;
	overflow-x: auto;
	overflow-y: auto;
	width: 100%;
	max-height: calc(100vh);
	/* padding: 15px 0; */
	/* padding-top: 100px; */
}
.Products--title {
	color: red;
}
.ProductsStats .ListOfProducts {
	display: flex;
	flex-direction: column;
	gap: 7px;
	width: min-content;
	position: relative;
	padding: 15px 0;
}
.ProductsStats--Product {
	display: flex;
	/* margin-top: 15px; */
	align-items: center;
	background-color: #fff;
	width: 100%;
}
.ProductsStats .coll {
	width: var(--width);
	color: gray;
	flex-shrink: 0;
	flex-grow: 0;
}
.ProductsStats .coll:not(:last-child) {
	margin-right: 1rem;
	border-right: 1px solid hsl(0 0% 25% / 1);
}
.ProductsStats .img-wrap {
	max-width: 3rem;
	min-width: 3rem;
	width: 3rem;
	flex-shrink: 0;
	flex-grow: 0;
}
.ProductsStats--Product img {
	width: 2.5rem;
	max-height: 2.5rem;
	height: 100%;
	object-fit: contain;
	object-position: center;
	margin-right: 15px;
	margin-left: 5px;
}
.ProductsStats--Product .wrap {
	display: flex;
	padding: 5px;
	padding-left: 0;
	padding-right: 0;
	align-items: center;
	width: 100%;
	/* grid-template-columns: 15fr 2fr 2fr; */
}
.ProductsStats--Product .name,
.ProductsStatsTitles .coll.name {
	font-weight: normal;
	font-size: inherit;
	/* width: 70%; */
	/* width: 100%; */
	width: 400px;
	margin-right: 1rem;
	margin: 0;
	line-height: 1.3;
	/* min-width: 200px; */
}

.ProductsStats input {
	margin-right: 1rem;
	max-width: 100px;
	border: 1px solid gray;
}

.ProductsStatsTitles {
	position: sticky;
	top: 0px;
	display: flex;
	background-color: hsl(0 0% 25% / 1);
	padding-top: 7px;
	padding-bottom: 7px;
	box-shadow: 0px 3px 5px 0px #8080806b;
}
.ProductsStats .coll {
}
.ProductsStatsTitles .coll {
	color: white;
	font-size: 14px;
}
.ProductsStatsTitles .coll:not(:last-child) {
	border-right: 1px solid white;
	margin-right: 1rem;
}
