body .PageSettings {
	position: fixed;
	z-index: 3;
	right: 0;
	top: 70px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.SettingsItem {
	padding: 5px;
	color: white;
	background-color: var(--color-gray);
	border-radius: 5px;
	margin-top: 15px;
}
.PageSettings .SettingsItem {
	border-radius: 5px 0px 0px 5px;
}
.SettingsItem--toggle {
	display: flex;
	justify-content: flex-end;
}
.SettingsItem--content {
	max-width: 0;
	max-height: 0;
	transition: 400ms;
	padding: 0;
	overflow: hidden;
	color: white;
}
.SettingsItem--content.isOpen {
	padding: 5px;
	max-width: 400px;
	max-height: 700px;
}
.SettingsItem .ant-form-item-label > label {
	color: white;
}
.PageSettings .ant-form-item-control {
	align-items: flex-end;
}
.PageSettings .ant-form-item {
	min-width: 300px;
}
@media (max-width: 700px) {
	body .PageSettings  {
        z-index: 4;
        right: 60px;
        top: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .SettingsItem{
        margin-top: 0;
    }
    .SettingsItem--content{
        right: 10px;
        background-color: var(--color-gray);
        position: fixed;
        top: 40px;
        padding-top: 30px;
    }
    .SettingsItem.isOpen{
        padding-top: 30px;
        max-width: calc(100vw - 20px);
    }
}
