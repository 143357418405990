main.page-products {
	display: flex;
	flex-direction: column;
}
.Category--top {
	display: flex;
	/* flex-wrap: wrap; */
}
.Category {
	position: relative;
	width: 100%;
}
.Category .SettingsItem:not(:hover) {
	opacity: 0.7;
}
.Category .SettingsItem {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
}
.Category .SettingsItem--toggle {
	justify-content: flex-start;
}
.Category--top .wrap {
	display: flex;
	width: 100%;
}
.Category--top .wrap > span {
	flex-grow: 1;
}
.Category--top .wrap .Category--top-totalPrice {
	flex-grow: 0;
	display: flex;
	align-items: center;
}
.Category--top .ant-divider-horizontal.ant-divider-with-text {
	white-space: normal;
}
.Category--top .ant-divider-horizontal.ant-divider-with-text::after {
	max-width: 30%;
}
@media (max-width: 700px) {
	.Category--top .ant-divider-horizontal.ant-divider-with-text::after,
	.Category--top .ant-divider-horizontal.ant-divider-with-text::before {
		display: none;
	}
}
.Products {
	/* padding: 15px; */
}
.Products--title {
	color: red;
}
.ListOfProducts {
	/* display: flex; */
	display: grid;
	gap: 7px;
	width: 100%;
	grid-auto-flow: column;
}
@media (max-width: 700px) {
	.ListOfProducts {
		flex-direction: column;
		display: flex;
	}
}
.sct-Products {
	width: 100%;
	margin-right: 15px;
}
.Product {
	display: flex;
	/* margin-top: 15px; */
	flex-direction: column;
	background-color: #fff;
	width: 100%;
}
.Product .wrap {
	display: flex;
	/* margin-top: 15px; */
	align-items: center;
	background-color: #fff;
	width: 100%;
}
.Category.titles {
	background-color: transparent;
}
.Category.titles .img-wrap {
	/* max-width: 30px; */
	/* min-width: 30px; */
}
.Category.titles .coll {
	/* padding-right: 15px; */
}
.Category.titles .ant-divider {
	/* margin-left: -25px; */
}
.Category.titles .ant-divider-horizontal {
	/* margin-right: 20px !important; */
}
.Product .coll {
	width: 20%;
}
.Product .img-wrap {
	max-width: 3rem;
	min-width: 3rem;
}
.Product img {
	width: 2.5rem;
	max-height: 2.5rem;
	height: 100%;
	object-fit: contain;
	object-position: center;
	margin-right: 15px;
}
.Product .wrap {
	display: flex;
	padding: 5px;
	align-items: center;
	width: 100%;
	/* grid-template-columns: 15fr 2fr 2fr; */
}
.Product .wrap .stock {
	width: 120px;
}

.Product .name {
	font-weight: normal;
	font-size: inherit;
	/* width: 70%; */
	width: 100%;
	margin: 0;
	line-height: 1.3;
	/* min-width: 200px; */
}

.PageProductsSettings {
}
.Filter {
	margin: 0;
	padding: 0;
}
.Filter li {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}
.Filter li.excluded {
	text-decoration: line-through;
	opacity: 0.8;
}
.Filter .ant-checkbox-wrapper {
	color: white;
}
.production-filter {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	margin: 10px 0;
}
.production-filter button:empty {
	display: none;
}
@media (max-width: 700px) {
	.production-filter .title {
		display: none;
	}
}

.highlighter {
	color: var(--color-assent);
	background-color: transparent;
}
