.Authorization{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
	background-color: #f0f2f5;
}
.Authorization form{
    padding: 15px;
    max-width: 300px;
    background-color: #fff;
}